<template>
    <v-chip-group multiple
                  column
                  active-class="primary"
                  v-model="userRoles">
        <v-chip v-for="role in allRoles"
                :key="role"
                :disabled="disabled">{{role}}</v-chip>
    </v-chip-group>
</template>
<script>
    import _ from 'lodash';
    import { roles } from '@/helpers/security';

    const allRolesArray = _.toArray(roles);

    export default {
        props: {
            value: {
                type: Array,
                required: true,
            },
            disabled: Boolean,
        },
        computed: {
            allRoles: () => allRolesArray,
            userRoles: {
                get() {
                    return this.value.map(role => this.allRoles.indexOf(role));
                },
                set(newRoles) {
                    this.$emit('input', newRoles.map(role => this.allRoles[role]));
                },
            },
        },
    };
</script>