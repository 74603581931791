<template>
    <v-text-field v-model="search"
                  append-icon="mdi-magnify">
        <!-- <template #append>
            <v-icon>mdi-magnify</v-icon>
        </template> -->
    </v-text-field>
</template>
<script>
    export default {
        name: 'search-box',
        props: {
            value: String,
        },
        computed: {
            search: {
                get() {
                    return this.value;
                },
                set(newSearch) {
                    this.$emit('input', newSearch);
                },
            },
        },
    };
</script>