<template>
    <app-navigation heading="Staff"
                    class="mb-4">
        <template :class="{'d-none': addStaffMember }"
                  #toolbar>
            <v-spacer></v-spacer>
            <v-col cols="8"
                   sm="6"
                   md="4"
                   lg="3">
                <search-box v-model="search"
                            v-focus></search-box>
            </v-col>
        </template>
        <v-container :class="{'d-none': addStaffMember }">
            <v-row>
                <v-col v-for="staffMember in staff"
                       :key="staffMember.username"
                       :class="{'d-none': searchedStaff.indexOf(staffMember) < 0 }"
                       cols="12"
                       md="6">
                    <staff-details :user="staffMember"></staff-details>
                </v-col>
            </v-row>
        </v-container>
        <v-speed-dial :class="{'d-none': addStaffMember }"
                      v-model="fab"
                      fixed
                      bottom
                      right
                      open-on-hover
                      transition="scale-transition">
            <template #activator>
                <v-btn v-model="fab"
                       color="blue darken-2"
                       dark
                       large
                       fab>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-dots-horizontal</v-icon>
                </v-btn>
            </template>
            <v-btn fab
                   title="Add New Staff Member"
                   dark
                   color="green"
                   @click="addStaff">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn fab
                   dark
                   title="Reload All Records"
                   color="grey"
                   @click="reloadAll">
                <v-icon>mdi-cached</v-icon>
            </v-btn>
        </v-speed-dial>
        <v-container v-if="addStaffMember">
            <v-row>
                <v-col cols=12
                       md=6>
                    <staff-new @close="addStaffMember = false"></staff-new>
                </v-col>
            </v-row>
        </v-container>
    </app-navigation>
</template>

<script>
    import { mapGetters } from 'vuex';

    import appNavigation from '@/components/app-navigation';
    import searchBox from '@/components/search-box';
    import staffNew from '@/modules/admin/components/staff-new';
    import staffDetails from '@/modules/admin/components/staff-details';

    export default {
        name: 'staff-admin',
        components: {
            appNavigation,
            searchBox,
            staffNew,
            staffDetails,
        },
        data() {
            return {
                fab: false,
                addStaffMember: false,
                search: '',
            };
        },
        computed: {
            searchedStaff() {
                const lowerSearch = this.search.toLowerCase().split(' ');

                return !this.search
                    ? this.staff
                    : this.staff.filter(s =>
                          lowerSearch.reduce(
                              (isMatch, name) =>
                                  isMatch &&
                                  (s.firstName.toLowerCase().indexOf(name) >= 0 ||
                                      s.surname.toLowerCase().indexOf(name) >= 0 ||
                                      s.username.toLowerCase().indexOf(name) >= 0),
                              true
                          )
                      );
            },
            ...mapGetters('organisation/admin', {
                staff: 'orderedStaff',
            }),
        },
        methods: {
            addStaff() {
                this.fab = false;
                this.addStaffMember = true;
            },
            reloadAll() {
                this.$store.dispatch('organisation/admin/staffLoadAll', {
                    force: true,
                });
            },
        },
        created() {
            this.$store.dispatch('organisation/admin/staffLoadAll', {});
        },
    };
</script>