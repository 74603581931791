<template>
    <v-card v-if="isDuplicate">
        <v-card-title>Duplicate record</v-card-title>
        <v-card-text>
            <p>Good news, or at least maybe it's good news...</p>
            <p class="mb-0">We already a staff member registered for the username: <em>{{username + emailDomain}}</em>. Would you like to go back to all users or continue editing the user you are creating?</p>
        </v-card-text>
        <card-actions>
            <v-btn text
                   key="exit"
                   color="primary"
                   @click="$emit('close')">Go Back</v-btn>
            <template #secondary>
                <v-btn text
                       key="continue"
                       @click="isDuplicate = false">Continue Editing</v-btn>
            </template>
        </card-actions>
    </v-card>
    <staff-card v-else
                card-title="New Staff Member"
                is-new
                :assignable.sync="assignable"
                :email-domain="emailDomain"
                :first-name.sync="firstName"
                :roles.sync="roles"
                :saving="saving"
                :surname.sync="surname"
                :username.sync="username"
                @is-valid="isValid = $event">
        <span :title="saveBtnTitle">
            <v-btn :disabled="!isValid"
                   :loading="saving"
                   text
                   key="save"
                   color="primary"
                   @click="save">Save</v-btn>
        </span>
        <template #secondary>
            <v-btn text
                   key="Cancel"
                   @click="$emit('close')">Cancel</v-btn>
        </template>
    </staff-card>
</template>

<script>
    import staffCard from './staff-card';

    export default {
        name: 'staff-new',
        components: {
            staffCard,
        },
        props: {},
        data() {
            return {
                assignable: true,
                confirmDelete: false,
                emailDomain: '@brinklit.org',
                firstName: null,
                isValid: false,
                isDuplicate: false,
                roles: [],
                saving: false,
                surname: null,
                username: null,
            };
        },
        computed: {
            saveBtnTitle() {
                return !this.isValid
                    ? 'You cannot save your changes unless all fields are valid'
                    : 'Save your changes';
            },
        },
        methods: {
            save() {
                this.firstName = this.firstName || '';
                this.surname = this.surname || '';
                if (
                    this.isValid &&
                    this.username &&
                    this.firstName &&
                    this.surname
                ) {
                    this.saving = true;
                    this.$store
                        .dispatch('organisation/admin/staffNew', {
                            assignable: this.assignable,
                            firstName: this.firstName,
                            roles: this.roles,
                            surname: this.surname,
                            username: this.username + this.emailDomain,
                        })
                        .then(() => {
                            this.saving = false;
                            this.$emit('close');
                        })
                        .catch(err => {
                            this.saving = false;

                            const resp = err.response;
                            if (
                                resp &&
                                resp.status === 409 &&
                                resp.data &&
                                resp.data.isDuplicate
                            ) {
                                return (this.isDuplicate = true);
                            }

                            this.$handleError(err);
                        });
                }
            },
        },
    };
</script>
