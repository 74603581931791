<template>
    <staff-card :assignable.sync="assignable"
                :card-title="user.firstName + ' ' + user.surname"
                :first-name.sync="firstName"
                :roles.sync="roles"
                :saving="saving"
                :surname.sync="surname"
                :username="user.username"
                @is-valid="isValid = $event">
        <span :title="saveBtnTitle">
            <v-btn :disabled="!anyChanges || !isValid"
                   :loading="saving"
                   text
                   key="save"
                   color="primary"
                   @click="save">Save</v-btn>
        </span>
        <template #secondary>
            <v-btn :disabled="!anyChanges"
                   text
                   key="Reset"
                   @click="reset">Reset</v-btn>
            <v-btn text
                   key="delete"
                   color="red"
                   @click="confirmDelete = true">Delete</v-btn>
            <v-dialog v-model="confirmDelete"
                      max-width="30%">
                <v-card>
                    <v-card-title>Remove {{user.firstName}} {{user.surname}}?</v-card-title>
                    <v-card-text>Are you sure you want remove {{user.firstName}} {{user.surname}}'s account from this organisation?</v-card-text>
                    <v-card-text>Removing the account will delete them from this organisation. They will still exist but will no longer be part of the assigning process</v-card-text>
                    <card-actions>
                        <v-btn text
                               color="red"
                               @click="deleteUser">Yes</v-btn>
                        <v-btn text
                               @click="confirmDelete = false">No</v-btn>
                    </card-actions>
                </v-card>
            </v-dialog>
        </template>
    </staff-card>
</template>

<script>
    import staffCard from './staff-card';

    export default {
        name: 'staff-details',
        components: {
            staffCard,
        },
        props: {
            user: {
                type: Object,
                required: true,
            },
        },
        data() {
            const user = this.user;
            return {
                assignable: user.assignable,
                confirmDelete: false,
                firstName: user.firstName,
                isValid: false,
                roles: user.roles,
                saving: false,
                surname: user.surname,
            };
        },
        computed: {
            anyChanges() {
                return (
                    this.assignableChanged ||
                    this.firstNameChanged ||
                    this.rolesChanged ||
                    this.surnameChanged
                );
            },
            assignableChanged() {
                return this.assignable !== this.user.assignable;
            },
            canSave() {
                return this.anyChanges && this.isValid;
            },
            firstNameChanged() {
                return this.firstName !== this.user.firstName;
            },
            rolesChanged() {
                const original = this.user.roles;
                return (
                    this.roles.length !== original.length ||
                    !this.roles.reduce(
                        (allMatch, r) => allMatch && original.indexOf(r) >= 0,
                        true
                    )
                );
            },
            saveBtnTitle() {
                return this.canSave
                    ? 'Save your changes'
                    : !this.isValid
                    ? 'You cannot save your changes unless all fields are valid'
                    : 'There are no changes to save';
            },
            surnameChanged() {
                return this.surname !== this.user.surname;
            },
        },
        methods: {
            save() {
                if (this.anyChanges && this.isValid) {
                    const changes = {};
                    if (this.assignableChanged)
                        changes.assignable = this.assignable;
                    if (this.firstNameChanged) changes.firstName = this.firstName;
                    if (this.rolesChanged) changes.roles = this.roles;
                    if (this.surnameChanged) changes.surname = this.surname;

                    this.saving = true;
                    this.$store
                        .dispatch('organisation/admin/staffUpdate', {
                            changes,
                            id: this.user._id,
                        })
                        .then(() => {
                            this.saving = false;
                        })
                        .catch(err => {
                            this.saving = false;
                            this.$handleError(err);
                        });
                }
            },
            deleteUser() {
                this.$store
                    .dispatch('organisation/admin/staffDelete', {
                        id: this.user._id,
                    })
                    .then(() => {
                        this.confirmDelete = false;
                    })
                    .catch(err => this.$handleError(err));
            },
            reset() {
                const user = this.user;
                this.firstName = user.firstName;
                this.surname = user.surname;
                this.roles = user.roles;
                this.assignable = user.assignable;
            },
        },
    };
</script>
