<template>
    <v-card>
        <v-card-title>{{cardTitle}}
            <v-spacer></v-spacer>
            <v-icon v-if="isInvalid"
                    color="red">fa-exclamation-triangle</v-icon>
        </v-card-title>
        <v-card-text>
            <v-text-field v-if="!isNew"
                          :value="usernameBound"
                          disabled
                          label="Email Address">
            </v-text-field>
            <v-row>
                <v-col cols="12"
                       sm="6"
                       md="12"
                       lg="6"
                       class="py-0">
                    <v-text-field v-model="firstNameBound"
                                  label="First Name"
                                  :disabled="saving"
                                  :error-messages="firstNameInvalid ? 'User\'s first name is required' : ''"
                                  class="pt-2 my-4"
                                  counter="50"
                                  maxlength="50">
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                       sm="6"
                       md="12"
                       lg="6"
                       class="py-0">
                    <v-text-field v-model="surnameBound"
                                  label="Surname"
                                  :disabled="saving"
                                  :error-messages="surnameInvalid ? 'User\'s surname is required' : ''"
                                  class="pt-2 my-4"
                                  counter="50"
                                  maxlength="50">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-text-field v-if="isNew"
                          v-model="usernameBound"
                          label="Email Address"
                          :error-messages="usernameInvalid ? 'A username is required' : ''">
                <template v-if="emailDomain"
                          #append><em>{{emailDomain}}</em></template>
            </v-text-field>
            <span class="subheading">Assigning</span>
            <div title="Will the user show up as someone who can be assigned submissions. For example, switch off if they are on holiday. The user will also only show up if they have the correct role">
                <v-switch v-model="assignableBound"
                          label="Available for assigning submissions"
                          :disabled="saving"
                          hide-details></v-switch>
            </div>
        </v-card-text>
        <v-card-text>
            <span class="subheading">Roles</span>
            <role-chips v-model="rolesBound"
                        :disabled="saving"></role-chips>
        </v-card-text>
        <card-actions>
            <slot></slot>
            <template #secondary>
                <slot name="secondary"></slot>
            </template>
        </card-actions>
    </v-card>
</template>

<script>
    import roleChips from './role-chips';

    export default {
        name: 'staff-details',
        components: {
            roleChips,
        },
        props: {
            assignable: Boolean,
            cardTitle: {
                type: String,
                required: true,
            },
            emailDomain: String,
            firstName: String,
            isNew: {
                type: Boolean,
                default: false,
            },
            roles: {
                type: Array,
                default: () => [],
            },
            saving: Boolean,
            surname: String,
            username: String,
        },
        data() {
            return {
                usernameTouched: false,
            };
        },
        computed: {
            assignableBound: {
                get() {
                    return this.assignable;
                },
                set(newAssignable) {
                    this.$emit('update:assignable', newAssignable);
                },
            },
            firstNameBound: {
                get() {
                    return this.firstName;
                },
                set(newFirstName) {
                    this.$emit('update:firstName', newFirstName);
                    this.guessUsername(newFirstName, this.surnameBound);
                },
            },
            firstNameInvalid() {
                return this.firstName === '';
            },
            isInvalid() {
                const isInvalid = this.firstNameInvalid || this.surnameInvalid;
                this.$emit('is-valid', !isInvalid);
                return isInvalid;
            },
            rolesBound: {
                get() {
                    return this.roles;
                },
                set(newRoles) {
                    this.$emit('update:roles', newRoles);
                },
            },
            surnameBound: {
                get() {
                    return this.surname;
                },
                set(newSurname) {
                    this.$emit('update:surname', newSurname);
                    this.guessUsername(this.firstNameBound, newSurname);
                },
            },
            surnameInvalid() {
                return this.surname === '';
            },
            usernameBound: {
                get() {
                    return this.username;
                },
                set(newUsername) {
                    this.usernameTouched = true;
                    this.$emit('update:username', newUsername);
                },
            },
            usernameInvalid() {
                return this.username === '';
            },
        },
        methods: {
            emitUsername(username) {
                this.$emit('update:username', username);
            },
            guessUsername(firstName, surname) {
                if (this.isNew && !this.usernameTouched) {
                    this.$emit(
                        'update:username',
                        (firstName || '').substr(0, 1).toLowerCase() +
                            (surname || '').toLowerCase()
                    );
                }
            },
        },
    };
</script>
